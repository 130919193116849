<template>
	<div class="order-goods">
		<!-- 状态tab -->
		<div class="WeCom-nav">
			<div class="nav-item" :class="activeIndex==index?'active':''" v-for="(item,index) in navList" :key="item.id" @click="navItem(item,index)">{{item.name}}</div>
		</div>
		<!-- 查询 -->
		<el-input placeholder="请输入查询的备品号" v-model="inputVal" class="input-search">
			<el-button slot="append" @click="searchClick">查询</el-button>
		</el-input>
		<!-- 订单列表 -->
		<el-empty description="暂无数据" :image-size="80" v-if="orderList.total==0"></el-empty>
		<div class="order-list order-goods-list">
			<div class="order-list-item" v-for="(item,index) in orderList.data" :key="index">
				<div class="title">
					<div class="name line1">订单号：{{item.order_no}}</div>
					<span class="tag-blue">{{item.state_text}}</span>
				</div>
				<div class="info">
					<span>订单金额：￥{{item.pay_price}}</span>
					<span>运费：￥{{item.express_price}}</span>
					<span>数量：{{item.goods.reduce((sum, e) => sum + e.total_num, 0)}}</span>
				</div>
				<div class="content">
					<div class="left">
						<span class="connective-item">下单日期：{{item.create_time.split(' ')[0]}}</span>
					</div>
					<div class="right">
						<button class="btn-orange" @click="messageClick(item)">留言</button>
						<button class="btn-orange" @click="addClick(item)">地址</button>
						<button class="btn-orange" @click="viewClick(item)">查看</button>
					</div>
				</div>
			</div>
		</div>
		<van-pagination v-model="orderList.current_page" :total-items="orderList.total"
                        :show-page-size="orderList.per_page"
                        @change="pageChange"
                        force-ellipses />
		<!-- 留言弹窗 -->
		<popupInput ref="messageInput" placeholder="请输入留言" @defineClick="messageDefineClick"></popupInput>
		<!-- 留言地址 -->
		<popupInput ref="addInput" placeholder="请输入地址" @defineClick="addDefineClick"></popupInput>
	</div>
</template>

<script>
    import * as QywxApi from '@/api/qywx'
	import popupInput from '../components/popupInput.vue'
	export default {
		components:{
			popupInput
		},
		data() {
			return {
				currentPage: 1,
				activeIndex: 0,
				navList: [{
					id: 1,
					name: '全部',
                    value: 'all'
				},{
					id: 2,
					name: '已支付',
                    value: 'delivery'
				},{
					id: 3,
					name: '已发货',
                    value: 'received'
				},{
					id: 4,
					name: '已完成',
                    value: 'comment'
				}],
				inputVal: '',
                orderType: 'all',
				orderList:[]
			}
		},
        created() {
            this.searchClick()
        },
        methods: {
			// 查询
			searchClick(){
        const customerUserId = localStorage.getItem('customerUserId')
        if (!customerUserId) {
          this.$router.push('/qywx/custom')
          return
        }
                QywxApi.orderList({
                    page: this.currentPage,
                    userId: customerUserId,
                    type: this.orderType,
                    keyword: this.inputVal
                }).then(res => {
                    this.orderList = res.data.list
                })
			},
			//查看
			viewClick(item){
				this.$router.push('/qywx/orderDetails?order_id='+item.order_id)
			},
			//留言
			messageClick(item){
				this.$refs.messageInput.show(item.order_id, 'buyer_remark', item.buyer_remark)
			},
			//留言弹窗确定
			messageDefineClick(){

			},
			//地址
			addClick(item){
				this.$refs.addInput.show(item.order_id, 'address', item.address.region.province+item.address.region.city+item.address.region.region+item.address.detail)
			},
			//地址弹窗确定
			addDefineClick(){

			},
			// 状态选择
			navItem(item,index) {
				this.activeIndex = index
                this.orderType = item.value
                this.searchClick()
			},
            pageChange(page) {
                this.currentPage = page
                this.searchClick()
            }
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/assets/css/wecom.scss";
</style>
